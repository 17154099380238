import React from 'react';
import * as s from './s.module.css';
import cx from 'classnames';
import Card from './Card';
import Ico from '../../../assets/images/tokenEconomy/2x-ico-stake.png';

class StakingBody extends React.Component {
    render() {
        return (
            <section className={s.stakingBodyContainer}>
                <div className={s.stakingBodyContent}>
                <div className={s.center}>
                    <h3 className={s.title}>
                        VPP Staking
                    </h3>
                    <div>
                        <img src={Ico} className={s.blueButtonIco}/>
                    </div>
                </div>
                <div className={cx(s.secondTitle, s.mb_45)}>
                    Virtue Player Points (VPP) is the utility token that powers the Virtue ecosystem. 
                </div>
                <div className={cx(s.secondTitle, s.mb_15)}>
                    <div className={s.secondTitleText}> The Virtue Council</div>
                    <div className={s.secondTitleBlueLine}/>
                </div>
                <div className={cx(s.grayText, s.mb_15)}>
                    VPP holders who stake and lock their VPP tokens will gain membership to the Virtue Council which provides governance decisions* on updates to the Virtue ecosystem (excluding legal and regulatory affairs) and in return receive staking rewards and bonus chips for our play-to-earn game.
                </div>
                <div className={cx(s.grayText, s.mb_15)}>
                    Only Council members who have staked 200,000 VPP or greater can submit governance proposals, while anyone who submits any amount of VPP to the staking pool will receive voting power proportional to their stake.
                </div>
                <div className={cx(s.grayText, s.mb_15)}>
                    Participants in the Justice Staking Pool will also receive 10% of the ETH fees generated on Virtue during their respective staking period.
                </div>
                <div className={cx(s.blueText, s.mb_45)}>
                    *Note: Virtue will soon incorporate Snapshot (decentralized voting tool) into our staking pool to power our governance function.
                </div>
                <div className={cx(s.secondTitle, s.mb_15, s.mt_15)}>
                    <div className={s.secondTitleText}> Play-to-Earn Chips Bonus</div>
                    <div className={s.secondTitleBlueLine}/>
                </div>
                <div className={cx(s.grayText, s.mb_15)}>
                    All Virtue Council members (stakers) will receive a 1000 chips* bonus for participating in our governance program. These chips can be used to compete in our daily and weekly play-to-earn competitions.
                </div>
                <div className={cx(s.blueText, s.mb_45)}>
                    *Note: In order to receive the 1000 chips bonus, an individual must submit their VPP stake from the same wallet as their source of funds for their Virtue Account.
                </div>
                <div className={cx(s.thirdTitle, s.mb_45)}>
                    Open Staking Pools
                </div>
                <Card/>
            </div>
        </section>
        );
    }
}

export default StakingBody;