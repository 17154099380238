import React from 'react';
import * as s from './s.module.css';

import Header from './Header';
import Staking from './Staking';

interface Props {
    location: any,
}

class StakingPage extends React.Component<Props, {}> {
    render() {
        return (
            <section className={s.sectionContainer}>
                <div className={s.sectionContent}>
                    <Header title={'Token Economy'} location={this.props.location}/>
                    <Staking />
                </div>
            </section>
        )
    }
}

export default StakingPage;