import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import StakingPage from '../../components/TokenEconomy/stakingPage'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Staking" />
    <StakingPage location={location}/>
  </Layout>
)

export default IndexPage
