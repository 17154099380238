import React from 'react';
import * as s from './s.module.css';
import cx from 'classnames';

const card1 = [
    ['Time Period', '90 Days', 'ETH Rewards', '0'],
    ['Min Stake', '5,000 VPP', 'Governance', 'Proportional Voting'],
    ['Max Stake', '50,000 VPP', 'Chip Bonus ', '1000'],
    ['Pool Size', '2,000,000 VPP', 'Registration Open', 'June 6th'],
    ['APR', '25%', 'Registration Close', 'June 20th'],
];
const card2 = [
    ['Time Period', '90 Days', 'ETH Rewards', '0'],
    ['Min Stake', '5,000 VPP', 'Governance', 'Proportional Voting'],
    ['Max Stake', '50,000 VPP', 'Chip Bonus ', '1000'],
    ['Pool Size', '2,000,000 VPP', 'Registration Open', 'June 6th'],
    ['APR', '25%', 'Registration Close', 'June 20th'],
];
const card3 = [
    ['Time Period', '180 Days', 'ETH Rewards', '0'],
    ['Min Stake', '200,000 VPP', 'Governance', 'Proportional Voting'],
    ['Max Stake', 'No limit', 'Chip Bonus ', '1000'],
    ['Pool Size', '15,000,000 VPP', 'Registration Open', 'June 6th'],
    ['APR', '50%', 'Registration Close', 'July 6th'],
];
const card4 = [
    ['Time Period', '180 Days', 'ETH Rewards', '0'],
    ['Min Stake', '200,000 VPP', 'Governance', 'Proportional Voting'],
    ['Max Stake', 'No limit', 'Chip Bonus ', '1000'],
    ['Pool Size', '15,000,000 VPP', 'Registration Open', 'August 11th'],
    ['APR', '50%', 'Registration Close', 'August 26th'],
];

const cardList = [
    {
        body: card1,
        header: 'Player 1 (ETH)',
        link: 'https://stake.unifyre.io/virtuegaming/info/0x293b7fe0c36c8f50bd98844ed635bcb8d6770505/ETHEREUM'
    },
    {
        body: card2,
        header: 'Player 1 (BSC)',
        link: 'https://stake.unifyre.io/virtuegamingbsc/info/0xb7e542749ce1492d75ad956761b0f194b4a93688/BSC'
    },
    {
        body: card3,
        header: 'Justice 1 (ETH)',
        link: 'https://stake.unifyre.io/virtuegaming/info/0x239d86284fc1e2fbaeeab2b959579c5a0f910466/ETHEREUM'
    },
    {
        body: card4,
        header: 'Justice 2 (ETH)',
        link: 'https://stake.unifyre.io/virtuegaming/info/0xf3eb3a033d01bb28bd7bb5544e4ae8f17e0202c8/ETHEREUM'
    },
];

class Card extends React.Component {
    render() {
        const toReturn = cardList.map((card, idx) => <div key={`card-${idx}`} className={cx(s.stakingPoolsCard)}>
            <div className={cx(s.stakingPoolsCardLeftSide)}>
                    <p className={s.openStakingPoolsText}>{card.header}</p>
                    <a className={s.openStakingPoolsLink} href={card.link} target="_blank" rel="noreferrer">
                        <div className={s.openStakingPoolsButton}>
                            Stake
                        </div>
                    </a>
            </div>
            <div className={cx(s.stakingPoolsCardRightSide)}>
                {card.body.map((row,idy) =><div key={`card-${idy}`} className={cx(s.stakingPoolsCardRightSideRow)}>
                    <p className={cx(s.stakingPoolsCardRightSideLeft)}>
                        <span>{row[0]}</span>
                        <span>{row[1]}</span>
                    </p>
                    <p className={cx(s.stakingPoolsCardRightSideRight)}>
                        <span>{row[2]}</span>
                        <span>{row[3]}</span>
                    </p>
                </div>)}
            </div>
        </div>
        );
        return toReturn;
    }
}

export default Card;
