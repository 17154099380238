// extracted by mini-css-extract-plugin
export var blueButtonIco = "s-module--blueButtonIco--6bc34";
export var blueText = "s-module--blueText--13040";
export var center = "s-module--center--78587";
export var contentAnimation = "s-module--contentAnimation--a5d59";
export var grayText = "s-module--grayText--91d01";
export var mb_15 = "s-module--mb_15--a58a1";
export var mb_30 = "s-module--mb_30--a75d0";
export var mb_45 = "s-module--mb_45--2a8bb";
export var mb_60 = "s-module--mb_60--35f72";
export var mt_15 = "s-module--mt_15--181e2";
export var mt_30 = "s-module--mt_30--3ef31";
export var mt_45 = "s-module--mt_45--69872";
export var mt_60 = "s-module--mt_60--8b917";
export var overviewContent = "s-module--overviewContent--c45cf";
export var secondTitle = "s-module--secondTitle--d1648";
export var secondTitleBlueLine = "s-module--secondTitleBlueLine--db865";
export var secondTitleText = "s-module--secondTitleText--2f999";
export var stakingBodyContainer = "s-module--stakingBodyContainer--e1eec";
export var stakingBodyContent = "s-module--stakingBodyContent--d2f8d";
export var thirdTitle = "s-module--thirdTitle--dac75";
export var title = "s-module--title--e4750";